import request from "@/common/request";

export async function apiQueryUserDetail(){ // 查询当前用户详情
    return request({ url: '/auth/sysUser/queryLoginUser', method: "POST" });
}

export async function apiWechatUnBinding() { //微信解绑
    return request({ url: '/auth/wechat/unBindWechat', method: "POST" });
}
export async function apiCreateAPIKey(data: any) {
    return request({ url: "/video/sysConfig/createAccessKey", method: "POST", data });
}

export async function apiQueryAPIKey(id: any) {
    return request({ url: `video/sysConfig/getAccessKey/${id}` });
}

export async function apiStopApiKey(data: any) {
    return request({ url: `/video/sysConfig/stopAccessKey`, method: "POST", data });
}

export async function apiDeleteApiKey(data:any) {//删除密钥
    return request({ url: `/video/sysConfig/deleteAccessKey`, method: "POST",data});
}

export async function apiSendCodeToNewTelephone(data: any) { // 修改手机号发送验证码
    return request({ url: "/auth/sysUser/toChangeTelephone", method: "POST", params: data})
}

export async function apiChangeTelephone(data:any) { //修改用户手机号
    return request({ url: `/auth/sysUser/changeTelephone`, method: "POST",data });
}

//系统设置
export async function apiSourceList() {//获取外部库地址
    return request({ url: "/api/config/getSource" });
}


export async function apiCreateSource(data: any) {//添加外库
    return request({ url: "/api/config/addSource", method: "POST", data });
}

export async function apiEditSource(data: any) {//编辑外库
    return request({ url: "/api/config/editSource", method: "POST", data });
}

export async function apiDeleteSource(data: any) {//删除外库
    return request({ url: "/api/config/delSource", method: "POST", data });
}

export async function apiQueryOrganList(data:any) {//查询单位名称
    return request({ url: `/auth/sys/organ/list`, method: "POST", data});
}