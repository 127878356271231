import { Action } from "@/app.d";

const namespace = "video";

export interface State {
    loading: boolean;
    searchInputValue?: string;
    btnType?: number;
    imageIndex?: string;
    list: any[];
    isShowTable: boolean;
    totalRows: number;
    page: number;
    size: number;
    queryParams: any;
    detailData: any;
    distributeList: any[];
}

const state: State = {
    loading: false,
    searchInputValue: "",
    imageIndex: "image",
    btnType: 0,
    list: [],
    isShowTable: true,
    totalRows: 0,
    page: 1,
    size: 50,
    queryParams: {},
    detailData: {},
    distributeList: []
};

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionQueryList = `${namespace}/actionQueryList`;
export const actionDetail = `${namespace}/actionDetail`;
export const actionStoryDetail = `${namespace}/actionStoryDetail`;
export const actionOriginalImage = `${namespace}/actionOriginalImage`;
export const actionImageSecDetail = `${namespace}/actionImageSecDetail`;
export const actionGetImageStoryId = `${namespace}/actionGetImageStoryId`;
export const actionRemove = `${namespace}/actionRemove`;
export const actionVideoFileSearch = `${namespace}/actionVideoFileSearch`;
export const actionVideoFileDetail = `${namespace}/actionVideoFileDetail`;
export const actionVideoFileUpdate = `${namespace}/actionVideoFileUpdate`;
export const actionVideoFileGroupDetail = `${namespace}/actionVideoFileGroupDetail`;
export const actionVideoFileGroupUpdate = `${namespace}/actionVideoFileGroupUpdate`;


export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}