import { takeLatest, call, put, select } from "redux-saga/effects";
import { message } from "antd";
import {
    actionUpdateState,
    actionQueryList,
    actionQueryDetail,
    actionRegisterUser,
    actionUpdateUser,
    actionUnOrEnableUser,
    actionCheckUserName,
    actionCheckTelephone
} from "./index";
import {
    apiQueryList,
    apiQueryDetail,
    apiRegisterUser,
    apiUpdateUser,
    apiUnOrEnableUser,
    apiCheckUserName,
    apiCheckTelephone
} from "./api";

const requestPage = {
    pageNum: 1,
    pageSize: 10,
    lastLoginOrder: ''
};

//用户管理的增删改查

function* queryList(action: any) {
    yield put({
        type: actionUpdateState,
        payload: {
            isShowTable: true
        }
    });
    const queryParams = yield select((it: any) => it.user.queryUserParams);
    const newQueryPrams = Object.assign({}, requestPage, queryParams, action.payload);
    const res = yield call(apiQueryList, newQueryPrams);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                list: res.data.list,
                isShowTable: false,
                totalRows: res.data.total,
                queryUserParams: newQueryPrams
            },
        });
    } else {
        message.warning(res.message)
    }
}

function* queryDetail(action: any) {
    const res = yield call(apiQueryDetail, action.payload);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                detail: res.data
            },
        });
    } else {
        message.warning(res.message)
    }
}

function* registerUser(action: any) {
    const res = yield call(apiRegisterUser, action.payload);
    if (res.code === 0) {
        yield queryList({});
    } else {
        message.warning(res.message)
    }
}

function* updateUser(action: any) {
    const res = yield call(apiUpdateUser, action.payload);
    if (res.code === 0) {
        yield queryList({});
    } else {
        message.warning(res.message)
    }
}

function* unOrEnableUser(action: any) {
    const res = yield call(apiUnOrEnableUser, action.payload);
    if (res.code === 0) {
        message.success("操作成功");
        yield queryList({});
    }else{
        message.warning(res.message)
    }
}

function* checkUserName(action: any) {
    const res = yield call(apiCheckUserName, action.payload);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                userNameUsable: res.data
            },
        });
    }else{
        message.warning(res.message)
    }
}

function* checkTelephone(action: any) {
    const res = yield call(apiCheckTelephone, action.payload);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                telephoneUsable: res.data
            },
        });
    }else{
        message.warning(res.message)
    }
}

export function* rootSaga() {
    yield takeLatest(actionQueryList, queryList);
    yield takeLatest(actionQueryDetail, queryDetail);
    yield takeLatest(actionRegisterUser, registerUser);
    yield takeLatest(actionUpdateUser, updateUser);
    yield takeLatest(actionUnOrEnableUser, unOrEnableUser);
    yield takeLatest(actionCheckUserName, checkUserName);
    yield takeLatest(actionCheckTelephone, checkTelephone);
}