import request from "@/common/request";
// import { RequestPagination } from "@/app.d";

// interface ApiQueryResource extends RequestPagination {
//     account?: string;
//     userName?: string;
// }

export async function apiQueryList(data: any) {
    return request({ url: `/auth/sysUser/searchList`, method: "POST", data });
}

export async function apiQueryDetail(data: any) {
    return request({ url: `/auth/sysUser/queryUserById`, method: "POST", params: data });
}

export async function apiRegisterUser(data: any) {
    return request({ url: "/auth/sysUser/register", method: "POST", data });
}

export async function apiUnOrEnableUser(data: any) {
    return request({ url: `/auth/sysUser/deleteById`, method: "POST", params: data });
}

export async function apiUpdateUser(data: any) {
    return request({ url: "/auth/sysUser/updateById", method: "POST", data });
}

export async function apiCheckUserName(data: any) {
    return request({ url: "/auth/sysUser/checkUserName", method: "POST", params: data });
}

export async function apiCheckTelephone(data: any) {
    return request({ url: "/auth/sysUser/checkTelephone", method: "POST", params: data });
}
