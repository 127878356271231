import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import * as video from "@/pages/Home/model/";
import * as media from "@/pages/MediaResources/model/";
import * as login from "@/pages/Login/model/";
import * as user from "@/pages/User/model";
import * as logs from "@/pages/Logs/model";
import * as system from "@/pages/SystemConfig/model";
import * as distribute from "@/pages/Distribute/model/";

export interface MainState {
    video: video.State,
    media: media.State,
    login: login.State,
    user: user.State,
    logs: logs.State,
    system: system.State
}

const reducers = combineReducers({
    video: video.reducers,
    media: media.reducers,
    login: login.reducers,
    user: user.reducers,
    logs: logs.reducers,
    system: system.reducers,
    distribute: distribute.reducers,
    router: connectRouter(history)
});

export default reducers;
