import { takeLatest, call, put, select } from "redux-saga/effects";
import { message } from "antd";
import _ from 'lodash';
import moment from "moment";
// import store from "store";
// import storeKey from "@/config/localstorage";
import {
    actionUpdateState,
    actionQueryList,
    actionSystemList,
    actionRemoveMedia,
    actionCreateVideo,
    actiongetFileUrl,
    actionCheckoutNameIsExist,
    actionCreateVideoFileGroup,
    actionDistributeManage,
    actionVideoBatchDistribute,
    actionVideoDistribute,
    actionBackoutBatchDistribute
} from "./index";
import {
    apiDeleteById,
    apiDistributeList,
    apiCreateVideo,
    apiGetFileUrl,
    apiCheckoutNameIsExist,
    apiCreateVideoFileGroup,
    apiSystemList,
    apiDistributeManage,
    apiVideoBatchDistribute,
    apiVideoDistribute,
    apiBackoutBatchDistribute
} from "./api";

const requestPage = {
    pageSize: 30,
    order: "desc",
    page:1
};

function* queryList(action: any) {

    const createTimeBegin = moment().subtract(30, 'days').format("YYYY-MM-DD 00:00:00");
    const createTimeEnd = moment().format("YYYY-MM-DD 23:59:59");
    const queryParams = yield select((it: any) => it.distribute.queryParams);
    const newQueryPrams = Object.assign({}, requestPage, { createTimeBegin, createTimeEnd }, queryParams, action.payload);
    const res = yield call(apiDistributeList, newQueryPrams);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                list: res.data.list,
                isShowTable: false,
                totalRows: res.data.total,
                page: res.data.pageNum,
                size: res.data.pageSize,
                queryParams: newQueryPrams
            },
        });
    }
}

function* removeMedia(action: any) {
    const res = yield call(apiDeleteById, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else {
        yield queryList({});
    }
}

function* createVideo(action: any) {
    const res = yield call(apiCreateVideo, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else {
        yield queryList({});
    }
}

function* createVideoFileGroup(action: any) {
    const res = yield call(apiCreateVideoFileGroup, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else {
        yield queryList({});
    }
}
function* getFileUrl(action: any) {
    const res = yield call(apiGetFileUrl, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                filPath: res.data.fileUrl
            },
        });
    }
}
function* checkoutNameIsExist(action: any) {
    const res = yield call(apiCheckoutNameIsExist, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                isexist: res.data
            },
        });
    }
}
function* getSystemList(action: any) {
    const res = yield call(apiSystemList, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                systemList: res.data
            },
        });
    }
}
function* getDstributeManageList(action: any) {
    const res = yield call(apiDistributeManage, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                distributeList: res.data
            },
        });
    }
}
function* videoBatchDistribute(action: any) {
    const res = yield call(apiVideoBatchDistribute, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                distributeList: res.data
            },
        });
    }
}
function* videoDistribute(action: any) {
    const res = yield call(apiVideoDistribute, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                distributeList: res.data,
                loading: false
            },
        });
    }
}
function* backoutBatchDistribute(action: any) {
    const res = yield call(apiBackoutBatchDistribute, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield put({
            type: actionUpdateState,
            payload: {
                distributeList: res.data
            },
        });
    }
}
//**************************** */

export function* rootSaga() {
    yield takeLatest(actionQueryList, queryList);
    yield takeLatest(actionRemoveMedia, removeMedia);
    yield takeLatest(actionCreateVideo, createVideo);
    yield takeLatest(actiongetFileUrl, getFileUrl);
    yield takeLatest(actionCheckoutNameIsExist, checkoutNameIsExist);
    yield takeLatest(actionCreateVideoFileGroup, createVideoFileGroup);
    yield takeLatest(actionSystemList, getSystemList);
    yield takeLatest(actionDistributeManage, getDstributeManageList);
    yield takeLatest(actionVideoBatchDistribute, videoBatchDistribute);
    yield takeLatest(actionVideoDistribute, videoDistribute);
    yield takeLatest(actionBackoutBatchDistribute, backoutBatchDistribute);
}