import { takeLatest, call, put } from "redux-saga/effects";
import { message } from "antd";
import store from "store";

import local from "@/config/localstorage";
// import { clearCookie } from "@/common/request";

import {
    actionUpdateState,
    actionUserDetail,
    actionCreateAPIKey,
    actionQueryAPIKey,
    actionStopApiKey,
    actionCreateSource,
    actionSourceList,
    actionEditSource,
    actionDeleteSource,
    actionQueryOrganList,
    actionWechatUnBinding,
    actionChangeTelephone,
    actionDeleteApiKey
} from "./index";
import {
    apiQueryUserDetail,
    apiCreateAPIKey,
    apiQueryAPIKey,
    apiStopApiKey,
    apiCreateSource,
    apiSourceList,
    apiEditSource,
    apiDeleteSource,
    apiQueryOrganList,
    apiWechatUnBinding,
    apiChangeTelephone,
    apiDeleteApiKey
} from "./api";
// import store from "store";
// import localConfig from "@/config/localstorage";

function* queryUserDetail() {
    const res = yield call(apiQueryUserDetail);
    store.set(local.userInfo, res.data);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState, payload: {
                userDetail: res.data
            }
        });
    }
}

function* createAPIKey(action: any) {
    const res = yield call(apiCreateAPIKey, { sysOrganId: action.payload });
    if (res.code !== 0) {
        message.error(res.message);
    }
    yield queryAPIKey(action);
}

function* queryAPIKey(action: any) {
    const res = yield call(apiQueryAPIKey, action.payload);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState, payload: {
                apikeyList: res.data
            }
        });
    }
}

function* queryOrganList() {
    const res = yield call(apiQueryOrganList, {});
    if (res.code === 0) {
        yield put({
            type: actionUpdateState,
            payload: {
                organList: res.data
            },
        });
    }
}

function* stopApiKey(action: any) {
    const res = yield call(apiStopApiKey, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }
    yield queryAPIKey({
        payload: action.payload.sysOrganId
    });
}

//微信解绑
function* wechatUnBinding() {
    // const user = store.get(localConfig.userInfo);
    const res = yield call(apiWechatUnBinding);
    if (res.code !== 0) {
        message.error(res.message);
    } else {
        message.success('解绑成功');
        yield queryUserDetail();
        // yield getUserWechatBindingStatus({payload:user.id});

    }
}
//外部库管理
function* sourceList() {//查询外部库
    const res = yield call(apiSourceList);
    if (res.code === 0) {
        yield put({
            type: actionUpdateState, payload: {
                sourceList: res.data
            }
        });
    }
}

function* createSource(action: any) {//添加外库
    const res = yield call(apiCreateSource, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }
    yield sourceList();
}

function* editSource(action: any) {//编辑外库
    const res = yield call(apiEditSource, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }
    yield sourceList();
}

function* deleteSource(action: any) {//删除外库
    const res = yield call(apiDeleteSource, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }
    yield sourceList();
}

function* changeTelephone(action: any) {
    const res = yield call(apiChangeTelephone, action.payload);
    console.log(res);
    if (res.code === 0) {
        message.success('密码修改成功',2)
        // clearCookie();
    } else {
        message.warning(res.message)
    }
}

function* deleteApiKey(action: any) {//删除密钥
    const user = store.get(local.userInfo);

    const res = yield call(apiDeleteApiKey, action.payload);
    if (res.code !== 0) {
        message.error(res.message);
    }else{
        yield queryAPIKey({
            payload: user.sysOrganId
        });  
    }
}

export function* rootSaga() {
    yield takeLatest(actionUserDetail, queryUserDetail);
    yield takeLatest(actionCreateAPIKey, createAPIKey);
    yield takeLatest(actionQueryAPIKey, queryAPIKey);
    yield takeLatest(actionStopApiKey, stopApiKey);
    yield takeLatest(actionCreateSource, createSource);
    yield takeLatest(actionSourceList, sourceList);
    yield takeLatest(actionEditSource, editSource);
    yield takeLatest(actionDeleteSource, deleteSource);
    yield takeLatest(actionQueryOrganList, queryOrganList);
    yield takeLatest(actionWechatUnBinding, wechatUnBinding);
    yield takeLatest(actionChangeTelephone, changeTelephone);
    yield takeLatest(actionDeleteApiKey, deleteApiKey);

}