import axios, { AxiosRequestConfig } from "axios";
// import { message } from "antd";
import URI from "urijs";
import store from "store";

import myConfig from "@/config/";
import { Response } from "@/app.d";
import storeKey from "@/config/localstorage";

axios.defaults.validateStatus = () => true;
axios.defaults.timeout = 60000;

export function clearCookie() {
    Object.keys(storeKey).forEach((key: string) => {
        store.remove(storeKey[key]);
    });
    window.location.href = "/login";
}

function checkStatus(data: any) {
    if (data.data.code >= 200 && data.data.code <= 400) {
        return data.data;
    } else if (data.data.code === 401) {
        // 清除本地全部缓存
        clearCookie();
        return;
    } 
    // else if (data.data.code=== 403) {
    //     message.warning("您没有操作权限");
    // } else if (data.data.code === 404) {
    //     message.error("未找到资源");
    // } 
    // else if (data.data.code >= 500) {
    //     message.error("服务器错误");
    // }
    return data.data;
}

function checkData(data: Response<any>) {
    return data || { code: -1 };
}

function axiosRequest(config:AxiosRequestConfig, useMock?: boolean) {
    const user = store.get(storeKey.userInfo);
    // 解决 ie11 ajax 缓存问题
    if (!config.method || config.method.toUpperCase() === "GET") {
        if (config.url) {
            const uri = URI(config.url);
            config.url = uri.addQuery({ __timestamp__: Date.now() }).readable();
        }
    }
    config.baseURL = useMock ? myConfig.apiHostMock : myConfig.apiHost;
    // 根据类型判断当前是要下载文件，还是正常的请求
    if (config && config.data && config.data.downloadType) {
        config.responseType = "blob";
        delete config.data.downloadType;
    }
    if (user) {
        const key = "Authorization";
        config.headers = {};
        config.headers[key] = user.token;
    }
    return axios(config)
        .then(checkStatus)
        .then(checkData)
        .catch((err) => err);
}

export default (
    config: AxiosRequestConfig,
    useMock?: boolean,
) => {
    return axiosRequest(config, useMock);
};

export function download(url: string, params: any) {
    if (url) {
        let uri = URI(url);
        uri = uri.addQuery({ __timestamp__: Date.now() });
        if (params) {
            for (const p in params) {
                if (p) {
                    const para = {};
                    para[p] = params[p];
                    uri.addQuery(para);
                }
            }
        }

        url = myConfig.apiHost + uri.readable();
    }
    return { code: 0, msg: "ok", url };
}

export function requestOrCancel(config: AxiosRequestConfig, useMock?: boolean,) {
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    return {
        request: axiosRequest(config, useMock),
        source
    };
}
