import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider, message } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import zh_CN from "antd/lib/locale/zh_CN";
// import { Router } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { renderRoutes } from "react-router-config";
import "./index.scss";
// import App from './App';
// import * as serviceWorker from './serviceWorker';
import history from "./history";
import routes from "@/config/routes";

import store from "./store";
moment.locale("zh-cn");

const container = document.getElementById("root");
try {
	if (container) {
		const root = createRoot(container);
		root.render(
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ConfigProvider locale={zh_CN}>{renderRoutes(routes)}</ConfigProvider>
				</ConnectedRouter>
			</Provider>
		);
	}
} catch (e) {}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
