import { takeLatest, call, put } from "redux-saga/effects";
import { message } from "antd";

import {requestPage} from '@/helper/constants';

import { actionUpdateState, actionQueryLogs } from "./index";
import { apiQueryLogs } from "./api";

function* queryList(action: any) {
    yield put({
        type: actionUpdateState,
        payload: {
            isShowTable: true
        }
    });
    const newQueryPrams = Object.assign({}, requestPage, action.payload);
    const res = yield call(apiQueryLogs, newQueryPrams);
    if (res.code !== 0) {
        message.error(res.message);
        return;
    }
    yield put({
        type: actionUpdateState,
        payload: {
            list: res.data,
            isShowTable: false,
            totalRows: res.data.total,
            page: newQueryPrams.page,
            size: newQueryPrams.pageSize,
            queryParams: newQueryPrams
        },
    });
}

export function* rootSaga() {
    yield takeLatest(actionQueryLogs, queryList);
}