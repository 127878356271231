export interface Config {
    development: ConfigParams;
    production: ConfigParams;
}

export interface ConfigParams {
    apiHost: string;
    apiHostMock: string;
    title: string;
    token: string;
    dateFormat: string;
    apiUploadFile: string;
}

const config: Config = {
    development: {
        apiHost: "/v1",
        apiHostMock: "http://127.0.0.1:3001",
        apiUploadFile: "http://100.100.142.70:10074",
        title: "上海日报视频资源库",
        token: "qiniu",
        dateFormat: "YYYY-MM-DD HH:mm:ss"
    },
    production: {
        apiHost: "/v1",
        apiHostMock: "/",
        title: "上海日报视频资源库",
        token: "qiniu",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        apiUploadFile: "http://100.100.142.70:10074",
    },
};

export default config[process.env.NODE_ENV || ""] as ConfigParams;