import { Action } from "@/app.d";

const namespace = "logs";

export interface State {
    list: any[];
    isShowTable: boolean;
    totalRows: number;
    page: number;
    size: number;
    queryParams: any;
}

const state: State = {
    list: [],
    isShowTable: true,
    totalRows: 0,
    page: 1,
    size: 30,
    queryParams: {}
};

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionQueryLogs= `${namespace}/actionQueryLogs`;

export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}