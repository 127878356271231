import { Action } from "@/app.d";

const namespace = "distribute";

export interface State {
    list: any[];
    isShowTable: boolean;
    totalRows: number;
    page: number;
    size: number;
    detail: any;
    editCreateLoading: boolean;
    propsCurrent: number;
    uploadMultipleLoading: boolean;
    uploadFileOk: number;
    queryParams: any;
    organList: any[];
    userDetail:object;
    authList:any[];
    //******************************* */
    queryUploadList:any[];
    filPath:String;
    isexist: String;
    systemList: any[];
    distributeList: any[];
    loading: boolean;

}

const state: State = {
    list: [],
    isShowTable: true,
    totalRows: 0,
    page: 1,
    size: 30,
    detail: {},
    editCreateLoading: false,
    propsCurrent: 0,
    uploadMultipleLoading: false,
    uploadFileOk: 0,
    queryParams: {},
    organList: [],
    userDetail:{},
    authList:[],
        //******************************* */
    queryUploadList:[],
    filPath:'',
    isexist: '',
    systemList: [],
    distributeList: [],
    loading: false
                                                                                                                                                                                    };

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionQueryList = `${namespace}/actionQueryList`;
export const actionRemoveMedia = `${namespace}/actionRemoveMedia`;
export const actionCreateVideo = `${namespace}/actionCreateVideo`;
export const actiongetFileUrl = `${namespace}/actiongetFileUrl`;
export const actionCheckoutNameIsExist = `${namespace}/actionCheckoutNameIsExist`;
export const actionCreateVideoFileGroup = `${namespace}/actionCreateVideoFileGroup`;
export const actionSystemList = `${namespace}/actionSystemList`;
export const actionDistributeManage = `${namespace}/actionDistributeManage`;
export const actionVideoBatchDistribute = `${namespace}/actionVideoBatchDistribute`;
export const actionVideoDistribute = `${namespace}/actionVideoDistribute`;
export const actionBackoutBatchDistribute = `${namespace}/actionBackoutBatchDistribute`;
export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}