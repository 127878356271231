import { Action } from "@/app.d";

const namespace = "system";

export interface State {
    userDetail: any;
    apikeyList: any;
    sourceList: any;
    organList: any;
}

const state: State = {
    userDetail: {},
    apikeyList: [],
    sourceList: [],
    organList: []
};

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionUserDetail = `${namespace}/actionUserDetail`;
export const actionCreateAPIKey = `${namespace}/actionCreateAPIKey`;
export const actionQueryAPIKey = `${namespace}/actionQueryAPIKey`;
export const actionStopApiKey = `${namespace}/actionStopApiKey`;
export const actionWechatUnBinding = `${namespace}/actionWechatUnBinding`;
export const actionCreateSource = `${namespace}/actionCreateSource`;
export const actionSourceList = `${namespace}/actionSourceList`;
export const actionEditSource = `${namespace}/actionEditSource`;
export const actionDeleteSource = `${namespace}/actionDeleteSource`;
export const actionQueryOrganList = `${namespace}/actionQueryOrganList`;
export const actionChangeTelephone = `${namespace}/actionChangeTelephone`;
export const actionDeleteApiKey = `${namespace}/actionDeleteApiKey`;


export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}