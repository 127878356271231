import request from "./request";
import ExpriesCache from "@/helper/dataCache";

function handlePersonType(data: any) {
	const list: any = [];
	for (const key of Object.keys(data)) {
		list.push({
			id: key,
			name: data[key],
		});
	}
	return list;
}

// 获取人物类型
export async function personTypeList() {
	const key = "PERSONTYPE";
	let data = ExpriesCache.get(key);
	if (!data) {
		const res = await request({ url: `/api/headimage/type` });
		if (res.code === 0) {
			data = handlePersonType(res.data);
		}
		ExpriesCache.set(key, data, 3600);
	}
	return data || [];
}

// 获取角色
export async function getOrdinaryList(params?: any) {
	const res = await request({ url: `/auth/sys/role/getOrdinaryList`, params });
	return res.data;
}

// 获取图片来源
export async function imageSource(refresh?: boolean) {
	const key = "IMAGESOURCE";
	if (refresh) {
		ExpriesCache.delete(key);
	}
	let data = ExpriesCache.get(key);
	if (!data) {
		const res = await request({ url: `/api/source/list` });
		if (res.code === 0) {
			data = res.data.records;
		}
		ExpriesCache.set(key, data, 3600);
	}
	return data || [];
}

// 获取作者
export async function authorData(refresh?: boolean, params?: any) {
	const key = "AUTHOR";
	if (refresh) {
		ExpriesCache.delete(key);
	}
	let data = ExpriesCache.get(key);
	if (!data) {
		const res = await request({ url: `/api/author/list`, params });
		if (res.code === 0) {
			data = {
				data: res.data.records,
				pages: res.data.pages,
			};
		}
		ExpriesCache.set(key, data, 3600);
	}
	return data;
}

// 获取区域
export async function areasList() {
	const key = "AREALIST";
	let data = ExpriesCache.get(key);
	if (!data) {
		const res = await request({ url: `/api/area/list` });
		if (res.code === 0) {
			data = res.areaList;
		}
		ExpriesCache.set(key, data, 36000);
	}
	return data;
}

// 获取图片元素标签
export async function imageTags() {
	const key = "IMAGETAGS";
	let data = ExpriesCache.get(key);
	if (!data) {
		const res = await request({ url: "/api/tags/list" });
		if (res.code === 0) {
			data = res.data;
		}
		ExpriesCache.set(key, data, 36000);
	}
	return data;
}

// 文件下载日志埋点
export function collectDownload() {
	return request({ url: "/video/videoFile/downLoad", method: "POST" });
}

// 文件上传日志埋点
export function collectUpload(params: { status: number }) {
	return request({
		url: "/video/videoFile/uploadResult",
		method: "POST",
		params,
	});
}
