import { message } from "antd";
import { takeLatest, call, put } from "redux-saga/effects";
import { actionUpdateState, actionLogin, actionCodeLogin } from "./index";
import { apiLogin, apiCodeLogin } from "./api";
import history from "@/history";
import store from "store";
import local from "@/config/localstorage";

function* login(action: any) {
    const res = yield call(apiLogin, action.payload);
    if (res.code === 0) {
        store.set(local.userInfo, res.data);
        history.push("/");
        yield put({
            type: actionUpdateState,
            payload: {
                userInfo: res.userData,
            },
        });
    }
    else {
        message.error(res.message);
    }
}

function* codeLogin(action: any) {
    const res = yield call(apiCodeLogin, action.payload);
    if (res.code === 0) {
        if(res.data.verify) {
            store.set(local.userInfo, res.data.userLoginResponseVo);
            history.push("/");
            yield put({
                type: actionUpdateState,
                payload: {
                    userInfo: res.data.userLoginResponseVo,
                },
            });
        }
        else {
            message.error(res.data.tips || '验证码错误');
        }
    }
    else {
        message.error(res.message);
    }
}

export function* rootSaga() {
    yield takeLatest(actionLogin, login);
    yield takeLatest(actionCodeLogin, codeLogin);
}