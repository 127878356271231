import * as image from "@/pages/Home/model/saga";
import * as media from "@/pages/MediaResources/model/saga";
import * as login from "@/pages/Login/model/saga";
import * as user from "@/pages/User/model/saga";
import * as logs from "@/pages/Logs/model/saga";
import * as system from "@/pages/SystemConfig/model/saga";
import * as distribute from "@/pages/Distribute/model/saga";

function runSaga(sagaMiddlewares: any) {
    sagaMiddlewares.run(image.rootSaga);
    sagaMiddlewares.run(media.rootSaga);
    sagaMiddlewares.run(login.rootSaga);
    sagaMiddlewares.run(user.rootSaga);
    sagaMiddlewares.run(logs.rootSaga);
    sagaMiddlewares.run(system.rootSaga);
    sagaMiddlewares.run(distribute.rootSaga);
}

export default function createSaga(createStore: any){
    const store = createStore();
    return (sagaMiddlewares: any) => {
        runSaga(sagaMiddlewares);
        return store;
    }
}
