import { takeLatest, call, put } from "redux-saga/effects";

import { requestPage } from "@/helper/constants";

import {
	actionUpdateState,
	actionVideoFileSearch,
	actionVideoFileDetail,
	actionVideoFileGroupDetail,
} from "./index";
import {
	apiVideoFileSimpleSearch,
	apiVideoFileAdvanceSearch,
	apiVideoFileDetail,
	apiVideoFileGroupDetail,
} from "./api";
import { message } from "antd";

// 检索列表
function* searchVideoFile(action: any) {
	yield put({
		type: actionUpdateState,
		payload: { loading: true, list: [], totalRows: 0, page: 0, size: 0 },
	});
	const apiSearch =
		action.payload.apiType === "simple"
			? apiVideoFileSimpleSearch
			: apiVideoFileAdvanceSearch;
	const params = Object.assign(
		{},
		{ order: "desc" },
		requestPage,
		action.payload
	);
	delete params.apiType;
	const res = yield call(apiSearch, params);
	if (res.code === 0) {
		yield put({
			type: actionUpdateState,
			payload: {
				list: res.data.list,
				totalRows: res.data.total,
				page: res.data.pageNum,
				size: res.data.pageSize,
				loading: false,
			},
		});
	} else {
		message.error(res.message);
		yield put({
			type: actionUpdateState,
			payload: {
				loading: false,
			},
		});
	}
}

// 查询素材文件详情
function* queryVideoFile(action: any) {
	yield put({
		type: actionUpdateState,
		payload: { detailData: {}, loading: true },
	});
	const res = yield call(apiVideoFileDetail, action.payload);
	if (res.code === 0) {
		yield put({
			type: actionUpdateState,
			payload: {
				detailData: res.data,
				loading: false,
			},
		});
	} else {
		message.error(res.message);
	}
	yield put({ type: actionUpdateState, payload: { loading: false } });
}

// 查询成片文件详情
function* queryVideoFileGroup(action: any) {
	yield put({
		type: actionUpdateState,
		payload: {
			detailData: {},
			loading: true,
		},
	});
	const res = yield call(apiVideoFileGroupDetail, action.payload);
	if (res.code === 0) {
		yield put({
			type: actionUpdateState,
			payload: {
				detailData: res.data,
				loading: false,
			},
		});
	} else {
		message.warning(res.message, 2);
	}
	yield put({ type: actionUpdateState, payload: { loading: false } });
}

export function* rootSaga() {
	yield takeLatest(actionVideoFileSearch, searchVideoFile);
	yield takeLatest(actionVideoFileDetail, queryVideoFile);
	yield takeLatest(actionVideoFileGroupDetail, queryVideoFileGroup);
}
