import store from "store";
import { action, makeObservable, observable } from "mobx";
import storeKey from "@/config/localstorage";

export enum EPermissionCode {
	编辑 = "edit",
	上传 = "upload",
	分发 = "distribute",
	下载 = "download",
}

class AppModal {
	@observable userInfo?: {
		id: number;
		name: string;
		userName: string;
		sysOrganId: number;
		organName: string;
		telephone: string;
		token: string;
		permissionList: {
			id: number;
			code: string;
			name: string;
		}[];
		isAdmin: number;
		dataFlag: number;
		isBindWechat: null;
		lastLogin: string;
		headUrl: null;
	};

	constructor() {
		makeObservable(this);
	}

	@action
	getUserInfo = () => {
		this.userInfo = store.get(storeKey.userInfo);
	};

	checkPermission = (code: string, userId?: number | number[]) => {
		return (
			this.userInfo?.isAdmin === 1 ||
			(this.userInfo?.permissionList?.find((item) => item.code === code) &&
				(!userId || this.userInfo.dataFlag === 0 || userId === this.userInfo.id))
		);
	};
}

export const MApp = new AppModal();
