import { Action } from "@/app.d";

const namespace = "user";

export interface State {
    list: any[];
    isShowTable: boolean;
    totalRows: number;
    page: number;
    size: number;
    detail: any;
    roleList: any;
    queryUserParams:any;
    userNameUsable: boolean;
    telephoneUsable: boolean;
}

const state: State = {
    list: [],
    isShowTable: true,
    totalRows: 0,
    page: 1,
    size: 10,
    detail: {},
    roleList: [],
    queryUserParams: {},
    userNameUsable: true,
    telephoneUsable: true
};

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionQueryList = `${namespace}/actionQueryList`;
export const actionQueryDetail = `${namespace}/actionQueryDetail`;
export const actionRegisterUser = `${namespace}/actionRegisterUser`;
export const actionUpdateUser = `${namespace}/actionUpdateUser`;
export const actionUnOrEnableUser = `${namespace}/actionUnOrEnableUser`;
export const actionCheckUserName = `${namespace}/actionCheckUserName`;
export const actionCheckTelephone = `${namespace}/actionCheckTelephone`;




export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}