import { Action } from "@/app.d";
const namespace = "login";

export interface State {
    userInfo: any;
}

const state: State = {
    userInfo: {}
};

export const actionUpdateState = `${namespace}/actionUpdateState`;
export const actionLogin = `${namespace}/actionLogin`;
export const actionCodeLogin = `${namespace}/actionCodeLogin`;

export function reducers(prevState: State = state, action: Action) {
    switch (action.type) {
        case actionUpdateState:
            return { ...prevState, ...action.payload };
        default:
            return { ...prevState };
    }
}