import React, { Suspense } from "react";
import Loading from "@/components/Loading";
import history from "@/history";

const LayoutComponent = React.lazy(() => import("./Layout"));

const Layout: React.FC<{}> = (props: any) => {
    if(!localStorage.getItem('sbmz/userInfo')){
        history.push("/login");
    }
    return (
        <Suspense fallback={<Loading />}>
            <LayoutComponent {...props} />
        </Suspense>
    )
};

export default Layout;