import { takeLatest, call, put, select } from "redux-saga/effects";
import { message } from "antd";
import _ from "lodash";
import store from "store";
import storeKey from "@/config/localstorage";
import {
	actionUpdateState,
	actionQueryList,
	actionRemoveMedia,
	actionCreateVideo,
	actiongetFileUrl,
	actionCheckoutNameIsExist,
	actionCreateVideoFileGroup,
} from "./index";
import {
	apiDeleteById,
	apiQueryUserUploadList,
	apiCreateVideo,
	apiGetFileUrl,
	apiCheckoutNameIsExist,
	apiCreateVideoFileGroup,
} from "./api";
import { collectUpload } from "@/common/commonData";

const requestPage = {
	pageSize: 30,
	order: "desc",
	page: 1,
};

function* queryList(action: any) {
	let isPolling = _.get(action.payload, "isPolling", false);
	const payload = _.omit(action.payload, ["isPolling"]);
	yield put({
		type: actionUpdateState,
		payload: {
			isShowTable: isPolling ? false : true,
			editCreateLoading: false,
			propsCurrent: 0,
		},
	});
	const userId = store.get(storeKey.userInfo) && store.get(storeKey.userInfo).id;
	const queryParams = yield select((it: any) => it.media.queryParams);
	const newQueryPrams = Object.assign({}, requestPage, queryParams, payload, {
		userId,
	});
	const res = yield call(apiQueryUserUploadList, newQueryPrams);
	if (res.code === 0) {
		yield put({
			type: actionUpdateState,
			payload: {
				list: res.data.list,
				isShowTable: false,
				totalRows: res.data.total,
				page: res.data.pageNum,
				size: res.data.pageSize,
				queryParams: newQueryPrams,
			},
		});
	}
}

function* removeMedia(action: any) {
	const res = yield call(apiDeleteById, action.payload);
	if (res.code !== 0) {
		message.error(res.message);
	} else {
		yield queryList({});
	}
}

function* createVideo(action: any) {
	const res = yield call(apiCreateVideo, action.payload);
	collectUpload({
		status: res.code === 0 ? 0 : 1,
	});
	if (res.code !== 0) {
		message.error(res.message);
	} else {
		yield queryList({});
	}
}

function* createVideoFileGroup(action: any) {
	const res = yield call(apiCreateVideoFileGroup, action.payload);
	collectUpload({
		status: res.code === 0 ? 0 : 1,
	});
	if (res.code !== 0) {
		message.error(res.message);
	} else {
		yield queryList({});
	}
}
function* getFileUrl(action: any) {
	const res = yield call(apiGetFileUrl, action.payload);
	if (res.code !== 0) {
		message.error(res.message);
	} else {
		yield put({
			type: actionUpdateState,
			payload: {
				filPath: res.data.fileUrl,
			},
		});
	}
}
function* checkoutNameIsExist(action: any) {
	const res = yield call(apiCheckoutNameIsExist, action.payload);
	if (res.code !== 0) {
		message.error(res.message);
	} else {
		yield put({
			type: actionUpdateState,
			payload: {
				isexist: res.data,
			},
		});
	}
}
//**************************** */

export function* rootSaga() {
	yield takeLatest(actionQueryList, queryList);
	yield takeLatest(actionRemoveMedia, removeMedia);
	yield takeLatest(actionCreateVideo, createVideo);
	yield takeLatest(actiongetFileUrl, getFileUrl);
	yield takeLatest(actionCheckoutNameIsExist, checkoutNameIsExist);
	yield takeLatest(actionCreateVideoFileGroup, createVideoFileGroup);
}
