import request from "@/common/request";

//****************************************************************************************************
//查询分发视频列表
export async function apiDistributeList(params: any) {
	return request({
		url: "/video/distributeFile/searchPageList",
		method: "POST",
		data: params,
	});
}
// 组织列表
export async function apiSystemList(params: any) {
	return request({
		url: "/auth/sysOrgan/getSimpleSysOrganList",
		method: "GET",
		data: params,
	});
}
// 分发管理select列表
export async function apiDistributeManage(params: any) {
	return request({
		url: "/video/distributeFile/distributeManage",
		method: "POST",
		data: params,
	});
}
// 成片文件批量分发
export async function apiVideoBatchDistribute(params: any) {
	return request({
		url: "/video/distributeFile/videoBatchDistribute",
		method: "POST",
		data: params,
	});
}
// 成片文件分发
export async function apiVideoDistribute(params: any) {
	return request({
		url: "/video/distributeFile/videoDistribute",
		method: "POST",
		data: params,
	});
}
// 撤销批量分发
export async function apiBackoutBatchDistribute(params: any) {
	return request({
		url: "/video/distributeFile/backoutBatchDistribute",
		method: "POST",
		data: params,
	});
}

//查询上传模块列表
export async function apiQueryUserUploadList(params: any) {
	return request({
		url: "/video/distributeFile/searchPageList",
		method: "POST",
		data: params,
	});
}
//删除上传模块列表
export async function apiDeleteById(params: any) {
	return request({
		url: "/video/videoFile/deleteById",
		method: "POST",
		data: params,
	});
}

//上传模块-创建素材文件
export async function apiCreateVideo(params: any) {
	return request({
		url: "/video/videoFile/createVideo",
		method: "POST",
		data: params,
	});
}

//上传模块-创建成片文件
export async function apiCreateVideoFileGroup(params: any) {
	return request({
		url: "/video/videoFileGroup/createVideoFileGroup",
		method: "POST",
		data: params,
	});
}

//上传模块-获取文件储存路径
export async function apiGetFileUrl(params: any) {
	return request({ url: "/video/common/getFileUrl", data: params });
}

//上传模块-校验成片文件名称是否重复
export async function apiCheckoutNameIsExist(params: any) {
	return request({ url: `/video/videoFileGroup/checkoutNameIsExist`, params });
}
