interface LocalStorage {
  userInfo: string;
  imageSourceNextBefore: string;
  imageList: string;
  imagePages: string;
  progress:string;
  videoFileParams: string;
  videoListPageNum: number;
  videoType: string;
}

const namespace = `sbmz`;

export default {
  userInfo: `${namespace}/userInfo`,
  imageSourceNextBefore: `${namespace}/imageSourceNextBefore`,
  imageList: `${namespace}/imageList`,
  imagePages: `${namespace}/imagePages`,
  progress: `${namespace}/progress`,
  videoFileParams: `${namespace}/videoFileParams`,
  videoType: `${namespace}/videoType`,
  videoListPageNum: -1
} as LocalStorage;
