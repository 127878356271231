import React, { Suspense, useEffect } from "react";
import { RouteConfig } from "react-router-config";
import Loading from "@/components/Loading";
import Layout from "@/components/Layout";
import store from "store";
import storeKey from "@/config/localstorage";
import { MApp } from "@/models/app";

export interface RouteConfigExt extends RouteConfig {
	name: string;
	routes?: RouteConfigExt[];
}

// const HomeComponent = React.lazy(() => import("../pages/Home"));
const HomeListComponent = React.lazy(() => import("../pages/Home/List"));
const DetailComponent = React.lazy(() => import("../pages/Home/Detail"));
const LoginComponent = React.lazy(() => import("../pages/Login"));
const MediaResourcesComponent = React.lazy(
	() => import("../pages/MediaResources")
);
const distributeComponent = React.lazy(() => import("../pages/Distribute"));
const UserComponent = React.lazy(() => import("../pages/User"));
const SystemConfigComponent = React.lazy(() => import("../pages/SystemConfig"));
const DustbinComponent = React.lazy(() => import("../pages/Dustbin"));
const LogsComponent = React.lazy(() => import("../pages/Logs"));
const WeChartLoginComponent = React.lazy(
	() => import("../pages/WeChart/Login")
);
const WeChartBindAccountComponent = React.lazy(
	() => import("../pages/WeChart/BindAccount")
);

function WithRouter(ComponentChilden: any, props?: any) {
	if (!props || !props.location.pathname.startsWith("/video")) {
		store.set(storeKey.videoListPageNum, 1);
	}
	if (props && props.location.pathname.startsWith("/video/group")) {
		store.set(storeKey.videoType, "group");
	}

	useEffect(() => {
		MApp.getUserInfo();
	}, [window.location.pathname]);
	return (
		<Suspense fallback={<Loading />}>
			<ComponentChilden match={props ? props.match : {}} />
		</Suspense>
	);
}

const layoutRoutes: RouteConfigExt[] = [
	{
		name: "检索",
		path: `/`,
		exact: true,
		component: (props) => WithRouter(HomeListComponent, props),
	},
	{
		name: "检索",
		exact: true,
		path: "/video/:type",
		component: (props) => WithRouter(HomeListComponent, props),
	},
	{
		name: "检索",
		exact: true,
		isShow: true,
		auth: 0,
		key: "video",
		path: "/video/group",
		component: (props) => WithRouter(HomeListComponent, props),
	},
	{
		name: "检索详情",
		key: "video",
		path: "/video/:type/detail/:id",
		component: (props) => WithRouter(DetailComponent, props),
	},
	{
		name: "上传",
		path: `/media/resource`,
		isShow: true,
		auth: 0,
		key: "media",
		exact: true,
		component: () => WithRouter(MediaResourcesComponent),
	},
	{
		name: "上传详情",
		key: "media",
		path: "/media/:type/detail/:id",
		component: (props) => WithRouter(DetailComponent, props),
	},
	{
		name: "用户管理",
		path: `/user/check`,
		isShow: true,
		auth: 1,
		key: "user",
		exact: true,
		component: () => WithRouter(UserComponent),
	},
	{
		name: "统计日志",
		path: `/log/list`,
		isShow: true,
		auth: 1,
		key: "log",
		exact: true,
		component: () => WithRouter(LogsComponent),
	},
	{
		name: "分发",
		path: `/distribute/resource`,
		isShow: true,
		auth: 0,
		key: "distribute",
		exact: true,
		component: () => WithRouter(distributeComponent),
	},
	{
		name: "分发详情",
		key: "distribute",
		path: "/distribute/:type/detail/:id",
		component: (props) => WithRouter(DetailComponent, props),
	},
	{
		name: "用户配置",
		path: `/system/user/config`,
		exact: true,
		key: "system",
		component: () => WithRouter(SystemConfigComponent),
	},
	{
		name: "系统配置",
		path: `/system/config`,
		exact: true,
		key: "system",
		component: () => WithRouter(SystemConfigComponent),
	},
	{
		name: "回收站",
		path: `/dustbin`,
		isShow: true,
		auth: 0,
		key: "dustbin",
		component: () => WithRouter(DustbinComponent),
	},
];

const routes: RouteConfigExt[] = [
	{
		name: "登录",
		path: `/login`,
		exact: true,
		component: () => {
			return WithRouter(LoginComponent);
		},
	},
	{
		name: "微信授权登录",
		path: `/wechat/login`,
		exact: true,
		component: () => {
			return WithRouter(WeChartLoginComponent);
		},
	},
	{
		name: "微信绑定",
		path: `/wechat/bind`,
		exact: true,
		component: () => {
			return WithRouter(WeChartBindAccountComponent);
		},
	},
	{
		name: "首页",
		path: `/`,
		exact: false,
		component: Layout,
		routes: layoutRoutes,
	},
];

export default routes;
