import request from "@/common/request";

export function apiVideoFileSimpleSearch(data: any) {
	// 普通检索列表
	return request({
		url: "/video/videoFile/simpleSearch",
		method: "POST",
		data,
	});
}

export function apiVideoFileAdvanceSearch(data: any) {
	// 高级检索列表
	return request({
		url: "/video/videoFile/advanceSearch",
		method: "POST",
		data,
	});
}

export function apiMemberAutoComplete(data: any) {
	// 摄影、摄像等幕后人员姓名补全
	return request({
		url: "/video/videoFile/autoComplete",
		method: "POST",
		params: data,
	});
}
export function apiVideoTranscodePublish(params: { id: number }) {
	// 视频发布
	return request({
		url: "/video/videoTranscoding/publishVideoFile",
		method: "GET",
		params,
	});
}

export function apiVideoTranscodeCancelPublish(params: { id: number }) {
	// 取消发布
	return request({
		url: "/video/videoTranscoding/cancelPublishVideoFile",
		method: "GET",
		params,
	});
}

export function apiVideoFileDetail(data: any) {
	// 查询素材文件详情
	return request({
		url: "/video/videoFile/queryById",
		method: "POST",
		params: data,
	});
}

export function apiVideoFileUpdate(data: any) {
	// 素材文件更新
	return request({ url: "/video/videoFile/updateById", method: "POST", data });
}

export function apiVideoFileDelete(data: any) {
	// 素材文件组删除
	return request({
		url: "/video/videoFile/deleteById",
		method: "POST",
		params: data,
	});
}

export function apiVideoFileTranscoding(data: any) {
	// 视频转码
	return request({
		url: "/video/videoFile/videoFileTranscoding",
		method: "POST",
		data,
	});
}

export function apiVideoFileTranscodeDelete(data: any) {
	// 转码视频删除
	return request({
		url: "/video/videoTranscoding/deleteById",
		method: "POST",
		params: data,
	});
}

export function apiVideoFileGroupDetail(data: any) {
	// 查询成片文件详情
	return request({
		url: "/video/videoFileGroup/queryById",
		method: "POST",
		params: data,
	});
}

export function apiVideoFileGroupUpdate(data: any) {
	// 成片文件更新
	return request({
		url: "/video/videoFileGroup/updateById",
		method: "POST",
		data,
	});
}

export function apiVideoFileGroupDelete(data: any) {
	// 成片文件删除
	return request({
		url: "/video/videoFileGroup/deleteById",
		method: "POST",
		params: data,
	});
}

export function apiAffixUpdate(data: any) {
	// 附件更新
	return request({
		url: "/video/videoFileMaterial/updateById",
		method: "POST",
		data,
	});
}

export function apiAffixDelete(data: any) {
	// 附件删除
	return request({
		url: "/video/videoFileMaterial/deleteById",
		method: "POST",
		params: data,
	});
}

export function apiUploadVideoFile(data: any) {
	// 成片文件详情页文件上传
	return request({
		url: "/video/videoFile/uploadVideoFile",
		method: "POST",
		data,
	});
}

export function apiGetBucketName() {
	// 获取bucketName
	return request({ url: "/video/common/getBucketName", method: "GET" });
}

export function apiGetVideoResolution(data: any) {
	// 获取当前路径的分辨率
	return request({
		url: "/video/videoTranscoding/checkVideoResolution",
		method: "POST",
		params: data,
	});
}
// 成片文件分发
export async function apiVideoDistribute(params: any) {
	return request({
		url: "/video/distributeFile/videoDistribute",
		method: "POST",
		data: params,
	});
}
// 封面图设置
export async function apiCoverSetting(params: any) {
	return request({
		url: "/video/common/coverSetting",
		method: "POST",
		data: params,
	});
}
