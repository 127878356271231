import request from "@/common/request";

interface ApiLogin {
    account: string;
    password: string;
}

export async function apiSendCode(data: {userName: string}) { //预登录-发送验证码
    return request({url: '/auth/login/toLogin', method: "POST", params: data})
}

export async function apiLogin(data: ApiLogin) { // 账号密码登录
    return request({url: `/auth/login/`, method: "POST", data});
}

export async function apiCodeLogin(data: any) { // 验证码登录
    return request({url: `/auth/login/codeLogin`, method: "POST", params: data});
}

export async function apiLoginOut() { // 退出登录
    return request({url: '/auth/login/logout', method: "POST"});
}