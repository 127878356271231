import request from "@/common/request";

//****************************************************************************************************
//查询上传模块列表
export async function apiQueryUserUploadList(params: any) {
	return request({
		url: "/video/videoFile/queryUserUploadList",
		method: "POST",
		data: params,
	});
}
//删除上传模块列表
export async function apiDeleteById(params: any) {
	return request({
		url: "/video/videoFile/deleteById",
		method: "POST",
		data: params,
	});
}

//上传模块-创建素材文件
export async function apiCreateVideo(params: any) {
	return request({
		url: "/video/videoFile/createVideo",
		method: "POST",
		data: params,
	});
}

//上传模块-创建成片文件
export async function apiCreateVideoFileGroup(params: any) {
	return request({
		url: "/video/videoFileGroup/createVideoFileGroup",
		method: "POST",
		data: params,
	});
}

//上传模块-获取文件储存路径
export async function apiGetFileUrl(params: any) {
	return request({ url: "/video/common/getFileUrl", data: params });
}

//上传模块-校验成片文件名称是否重复
export async function apiCheckoutNameIsExist(params: any) {
	return request({ url: `/video/videoFileGroup/checkoutNameIsExist`, params });
}
