import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import createSaga from "./sagas";

import history from "./history";
import reducers from "./reducers";
const sagaMiddlewares = createSagaMiddleware();

export default createSaga(() => {
	return createStore(
		reducers,
		{},
		compose(applyMiddleware(sagaMiddlewares, routerMiddleware(history)))
	);
})(sagaMiddlewares);
